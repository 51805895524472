export const store = {
    revenueGenerated: 52000,
    orders: 120,
    dueAmount:10000,
    pendingAmount:10000,
    customers: 120,
    balance: 52000,
    mostSold: "Plain T-shirt",
    salesChange: 2.33,
  };
